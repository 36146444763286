import dynamic from 'next/dynamic'
import SkeletonCard from '../../skeleton/card/skeleton-card'

// DON'T USE VARIABLE FOR DYNAMIC OPTIONS, CAUSE Did not expect server HTML to contain a <div> in <div>
/* const dynamicOptions = {
	loading: SkeletonCard,
} */

const TileLookListing = dynamic(() => import('@/components/ui/molecules/tile/look-listing/tile-look-listing'), {
	loading: SkeletonCard,
})

export const customVariants = {
	lookListing: TileLookListing,
}
